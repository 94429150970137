import React from 'react';
import PropTypes from 'prop-types';
import Match from './match';

function MatchList(props) {
  return (
    <div className={`match-list-container ${props.containerClass}`}>
      {props.matches.map((match, index) => (
        <Match key={index} match={match} containerClass="mb-3" />
      ))}
    </div>
  );
}

MatchList.defaultProps = {
  containerClass: '',
  matches: [],
};

MatchList.propTypes = {
  containerClass: PropTypes.string,
  matches: PropTypes.array,
};

export default MatchList;
