import React from 'react';
import PropTypes from 'prop-types';
import Tag from './tag';

function TagList(props) {
  return (
    <div className={`tag-list-container d-flex flex-row align-items-center ${props.containerClass}`}>
      <h4 className="fw-normal fs-6 mb-0 px-2 py-1 border border-2 border-secondary me-3 bg-body-secondary-bg">Tags:</h4>
      {props.tags.map((tag, index) => (
        <Tag key={index} title={tag.title} uid={tag.uid} containerClass={`mx-2 text-decoration-none pb-1 border-bottom border-2 border-primary`} />
      ))}
    </div>
  );
}

TagList.defaultProps = {
  containerClass: '',
  tags: [],
};

TagList.propTypes = {
  containerClass: PropTypes.string,
  tags: PropTypes.array,
};

export default TagList;
