import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { getUrl } from '../utils/urlHelper';

function AuthorDateSection(props) {
  return (
    <div className={`author-date-container d-flex w-100 ${props.containerClass}`}>
      <Link to={getUrl('author', props.author.uid)} title={props.author.name} className="text-decoration-none text-secondary me-3 fw-bold">
        {props.author.name}
      </Link>
      <span className="me-3">{props.date}</span>
    </div>
  );
}

AuthorDateSection.defaultProps = {
  author: {},
  date: '',
  containerClass: '',
};

AuthorDateSection.propTypes = {
  author: PropTypes.object,
  date: PropTypes.string,
  containerClass: PropTypes.string,
};

export default AuthorDateSection;
