import React from 'react';
import PropTypes from 'prop-types';
import { dateFormatter } from '../../utils/utils';

function Match(props) {
  return (
    <div className={`match-container text-primary p-3 border border-primary position-relative ${props.containerClass}`}>
      <span className="position-absolute h-100 w-100 shadow"></span>
      <div className="position-relative">
        <div className="mb-1 text-center">
          <small>
            {dateFormatter(props.match.date)} - {props.match.stadium}
          </small>
        </div>
        <div className="mb-2 text-center fw-bold">
          <small>
            {props.match.competition} - {props.match.mathcDay}
          </small>
        </div>
        <div className="row row-cols-auto d-flex justify-content-center align-items-center m-auto">
          <div className="col">
            <img src={props.match.homeTeam.logo.url} className="img-fluid" alt={props.match.homeTeam.logo.alt} />
          </div>
          <div className="col text-center fs-1 fw-bold">
            {props.match.homeTeamGoals} - {props.match.guestTeamGoals}
          </div>
          <div className="col">
            <img src={props.match.guestTeam.logo.url} className="img-fluid" alt={props.match.guestTeam.logo.alt} />
          </div>
        </div>
      </div>
    </div>
  );
}

Match.defaultProps = {
  containerClass: '',
  match: {
    guestTeamGoals: 0,
    homeTeamGoals: 0,
  },
};

Match.propTypes = {
  containerClass: PropTypes.string,
  match: PropTypes.shape({
    competition: PropTypes.string,
    date: PropTypes.string,
    guestTeam: PropTypes.object,
    guestTeamGoals: PropTypes.number,
    homeTeam: PropTypes.object,
    homeTeamGoals: PropTypes.number,
    mathcDay: PropTypes.string,
    stadium: PropTypes.string,
    uid: PropTypes.string,
  }).isRequired,
};

export default Match;
