import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { getUrl } from '../../utils/urlHelper';

function Tag(props) {
  return (
    <Link to={getUrl('tag', props.uid)} className={props.containerClass}>
      {props.title}
    </Link>
  );
}

Tag.defaultProps = {
  containerClass: '',
  title: '',
};

Tag.propTypes = {
  containerClass: PropTypes.string,
  uid: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default Tag;
