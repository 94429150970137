import React from 'react';
import PropTypes from 'prop-types';
import Category from './category';

function CategoryList(props) {
  return (
    <div className={`category-list-container d-flex flex-row align-items-center ${props.containerClass}`}>
      <h4 className="fw-normal fs-6 mb-0 px-2 py-1 border border-2 border-secondary me-3 bg-body-secondary-bg">Κατηγορίες:</h4>
      {props.categories.map((category, index) => (
        <Category key={index} title={category.title} uid={category.uid} containerClass={`mx-2 text-decoration-none pb-1 border-bottom border-2 border-primary`} />
      ))}
    </div>
  );
}

CategoryList.defaultProps = {
  containerClass: '',
  categories: [],
};

CategoryList.propTypes = {
  containerClass: PropTypes.string,
  categories: PropTypes.array,
};

export default CategoryList;
