import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { getUrl } from '../../utils/urlHelper';

function Category(props) {
  return (
    <Link to={getUrl('category', props.uid)} className={props.containerClass}>
      {props.title}
    </Link>
  );
}

Category.defaultProps = {
  containerClass: '',
  title: '',
};

Category.propTypes = {
  containerClass: PropTypes.string,
  uid: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default Category;
