import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout/layout';
import AuthorDateSection from '../components/authorDateSection';
import { TeamsListInline } from '../components/teams';
import { CategoryList } from '../components/categories';
import { TagList } from '../components/tags';
import { MatchList } from '../components/matches';

function Post(props) {
  const data = props.pageContext.data;
  const author = {
    uid: data.author.uid,
    name: data.author.document.data.full_name.text,
  };

  const teams = data.teams.map(teamData => {
    return {
      uid: teamData.team.uid,
      title: teamData.team.document.data.name.text,
      logo: {
        url: teamData.team.document.data.avatar.url,
        alt: teamData.team.document.data.avatar.alt,
      },
    };
  });

  const categories = data.categories
    .filter(categoryData => {
      return categoryData.category.document.data.active;
    })
    .map(categoryData => {
      return {
        uid: categoryData.category.uid,
        title: categoryData.category.document.data.title.text,
      };
    });

  const tags = data.tags.map(tagData => {
    return {
      uid: tagData.tag.uid,
      title: tagData.tag.document.data.title.text,
    };
  });

  const matches = data.matches.map(matchData => {
    return {
      uid: matchData.match.uid,
      date: matchData.match.document.data.date,
      competition: matchData.match.document.data.competition.document.data.title.text,
      homeTeam: {
        uid: matchData.match.document.data.home_team.document.uid,
        name: matchData.match.document.data.home_team.document.data.name.text,
        logo: {
          url: matchData.match.document.data.home_team.document.data.avatar.url,
          alt: matchData.match.document.data.home_team.document.data.avatar.alt,
        },
      },
      guestTeam: {
        uid: matchData.match.document.data.guest_team.document.uid,
        name: matchData.match.document.data.guest_team.document.data.name.text,
        logo: {
          url: matchData.match.document.data.guest_team.document.data.avatar.url,
          alt: matchData.match.document.data.guest_team.document.data.avatar.alt,
        },
      },
      mathcDay: matchData.match.document.data.match_day.text,
      stadium: matchData.match.document.data.stadium.document.data.name.text,
      guestTeamGoals: matchData.match.document.data.guest_team_goals,
      homeTeamGoals: matchData.match.document.data.home_team_goals,
    };
  });

  return (
    <Layout pageTitle={data.title.text} seoTitle={data.seo_title} seoDescription={data.seo_description}>
      <AuthorDateSection author={author} containerClass="mb-3" date={data.date} />
      {matches.length > 0 && <MatchList matches={matches} containerClass={'mb-3'} />}
      <div className="mb-3" dangerouslySetInnerHTML={{ __html: data.description.html }}></div>
      {teams.length > 0 && <TeamsListInline teams={teams} containerClass={'mb-2'} />}
      {categories.length > 0 && <CategoryList categories={categories} containerClass={'mb-2'} />}
      {tags.length > 0 && <TagList tags={tags} containerClass={'mb-2'} />}
    </Layout>
  );
}

Post.defaultProps = {
  pageContext: {
    data: {},
  },
};

Post.propTypes = {
  pageContext: PropTypes.shape({
    data: PropTypes.object,
  }),
};

export default Post;
